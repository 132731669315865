(function() {

var Modules = {};

Modules['WHS'] = {};

Modules['WHS']['Config'] = (function ()
{
	return {
		hidden: '_hidden',
		
		// ImageCollection CSS classes:
		ImageCollection: {
			idPrefix: 'whs-image-collection-',
			imageCollection: 'whs-image-collection',
			toggleArea: 'whs-ic-toggle-area',
			toggle: 'whs-ic-toggle',
			toggleToSlideshow: 'whs-ic-toggle-to-slideshow',
			toggleToGallery: 'whs-ic-toggle-to-gallery',
			galleryImgNote: 'whs-gallery-img-note',
			slideshowImgNote: 'whs-slideshow-img-note',
			slide: 'whs-slide',
			slideTitle: 'whs-slide-title',
			slideImgFld: 'whs-slide-image-field',
			slideLink: 'whs-slide-link',
			slideImg: 'whs-slide-image',
			slideCaption: 'whs-slide-caption',
			slideNav: 'whs-slide-nav',
			slideNavButton: 'whs-slide-nav-button',
			thumbnails: 'whs-gallery-figures', // to be revised when(/if???) thumbnails made independent of gallery figures
			thumbnailImg: 'whs-figure', // " " " "
			gallery: 'whs-gallery-figures',
			galleryFigure: 'whs-figure',
			figButton: 'whs-figure-button',
			figTitle: 'whs-figure-title',
			figImgFld: 'whs-figure-image-field',
			figCaption: 'whs-figure-caption',
		}, // end .ImageCollection

		ColumnSet: 'whs-column-set',
		Column: 'whs-column',
		Block: 'whs-block',

		Nav: {
			headerRoot: 'SH-nav',
			headerRootId: 'site-header-nav',
			navToggle: 'SH-navToggle',
			navToggleId: 'site-header-nav-toggle',
			toggleIcon: 'SN-toggleIcon',
			siteMapId: 'site-map',
			tree: 'TOC-TableOfContents',
			nodeToggle: 'TOC-toggle',
			closer: 'TOC-closer',
			closerMainVariant: '-main',

			toggleLabel: {
				sub: {
					closed: 'Open submenu',
					open: 'Close submenu',
				},
				main: {
					closed: 'Show site menu',
					open: 'Hide site menu',
				},
			},
					
		},

		Search: {
			toggleId: 'search-toggle',
			formId: 'search-form',
			inputId: 'search-bar',

			toggleLabel: {
				closed: 'Show search bar',
				open: 'Hide search bar',
			},
		},

	}; // end Config object
})(Modules);



Modules['FEB'] = {};

Modules['FEB']['Containers'] = (function ()
{
	var U = { // "utilities"
	
		extras: function(short, long)
		{
			var ex = [];
			for (var i = short.length; i < long.length; ++i) {
				ex.push(long[i]);
			}
			return ex;
		},
		
		
		pushCopy: function(e, arr) {
			arr.push(C.copy(e));
			return arr;
		},
	
	
		applyOne: function(app, indexOrKey)
		{
			var inItem =
				(app.inputType === U.input.arrayElement) ? (
					app.isSingle ?
						app.input :
						app.input[indexOrKey]
				) :
				// else app.inputType should be U.input.arrayIndex or U.input.objectKey:
				indexOrKey;
		
			var outItem = (app.env.length > 0) ?
				app.mapF.apply(null, [inItem].concat(app.env)) :
				app.mapF(inItem);
		
			if (app.isSingle) {
				return (app.outputType === U.output.none) ? C : outItem;
			}
			else {
				switch (app.outputType)
				{
				case U.output.array:
					app.output.push(outItem);
					break;
				
				case U.output.object:
					var k = (app.inputType === U.input.objectKey) ?
						indexOrKey :
						// else inputType should be U.input.arrayElement for C.mapFieldList:
						app.input[indexOrKey];
					
					app.output[k] = outItem;
					break;
				
				} // else app.outputType should be U.output.none, in which case no-op bc app.mapF was already executed in outItem defn above
			}
		},


		makeMapFn: function(inType, outType)
		{
			return function selfF(input, mapF)
			{
				// optional env params let you pass any additional data needed as either plain vars or an object, etc
				var app = {
					mapF: mapF,
					input: input,
					inputType: inType,
					outputType: outType,
					env: U.extras(selfF, arguments),
					isSingle: (inType !== U.input.objectKey) && (! C.isArrayLike(input)),
				};
			
				if (app.isSingle) {
					return U.applyOne(app, 0);
				}
				else {
					app.output = 
						(outType === U.output.array) ? [] :
						(outType === U.output.object) ? {} :
						// else app.outputType should be U.output.none
						C;
				
					if (inType === U.input.objectKey) {
						for (var k in input) {
							U.applyOne(app, k);
						}
					}
					else { // inType should be U.input.arrayElement or U.input.arrayIndex
						for (var i = 0; i < input.length; ++i) {
							U.applyOne(app, i);
						}
					}
	
					return app.output;
				}
			};
		},
	
	
		input: {
			arrayElement: 0,
			arrayIndex: 1,
			objectKey: 2,
		},
	
		output: {
			none: 0,
			array: 1,
			object: 2,
		},

		objectStringIs: function(o, str) {
			return Object.prototype.toString.call(o) === str;
		},
		
	}; // end U




	
	var C = { // "containers"
	
		each: U.makeMapFn(
			U.input.arrayElement, U.output.none),
		
		map: U.makeMapFn(
			U.input.arrayElement, U.output.array),
		
		eachIndex: U.makeMapFn(
			U.input.arrayIndex, U.output.none),
		
		mapIndex: U.makeMapFn(
			U.input.arrayIndex, U.output.array),
		
		eachField: U.makeMapFn(
			U.input.objectKey, U.output.none),
		
		mapFields: U.makeMapFn(
			U.input.objectKey, U.output.object),
		
		mapFieldList: U.makeMapFn(
			U.input.arrayElement, U.output.object),

		mapFieldsToList: U.makeMapFn(
			U.input.objectKey, U.output.array),
			
			
		loadOnce: function(defn) {
			// 181230 need to bring back extra args???
			return function() {
				if (! defn.hasBeenLoaded) {
					defn = defn();
					defn.hasBeenLoaded = true;
				}
				return defn;
			};
		},
		
		
		// lazyFn is similar to loadOnce but the result is a function that gets called with the supplied arguments, even on the first call - basically it's like an IIFE but the invocation is deferred to first call
		lazyFn: function(defn) {
			return function() {
				if (! defn.hasBeenLoaded) {
					defn = defn();
					defn.hasBeenLoaded = true;
				}
				return defn.apply(null, arguments);
			};
		},


		// runOnDomReady takes a callback that runs once DOMContentLoaded fires or once document.readyState becomes 'interactive' - the point is to run as soon as the DOM is built, rather than waiting for all resources (e.g. images) to load, which is the case with the 'load' event; adapted from https://javascript.info/onload-ondomcontentloaded
		runOnDomReady: function(f, options) {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', f, options || false);
			} else {
				f();
			}
		},
		
		
		// for printing the stack trace without interrupting execution:
		logFullError: function(errorMsg) {
			try {
				throw Error(errorMsg);
			} catch (err) {
				console.error(err);
			}
		},
		
		
		// WATCH OUT - this and its dependent fns won't work in Node vm, iframes, etc. because of different "class" objects being defined for each
		is: function(o, type) {
			return type.prototype.isPrototypeOf(o);
		},
	
		isString: function(o) {
			// note: (return C.is(o, String);) doesn't work bc String is sort of a primitive type, or something like that

			return (typeof o === String) || U.objectStringIs(o, '[object String]');
		},
	
		// isArray polyfill from MDN
		isArray: Array.isArray || function(o) {
			return U.objectStringIs(o, '[object Array]');
		},
	
		isFunction: function(o) {
			return U.objectStringIs(o, '[object Function]');
		},
	
		// This returns true for arrays, functions, etc. since they inherit from Object! Depending on usage, either should redefine this or maybe rename it and the other isX's to something like instanceOfObject - speaking of which, is this exactly the same as (instanceof)?
		isObject: function(o) {
			return U.objectStringIs(o, '[object Object]');
		},
	
		isArrayLike: function(o) {
			return (
				C.isArray(o) ||
				C.is(o, NodeList) ||
				C.is(o, HTMLCollection)
			);
		},
		
		// note this counts prototype properties, I think?
		isEmpty: function(o) {
			for (var k in o) {
				return false;
			}
			return true;
		},
		
		makeArray: function(o) {
			return C.isArrayLike(o) ? o : [o];
		},
	
		isDomNode: function(o) {
			return C.is(o, Node);
		},
		
		
		add: function(src, dest, exclude)
		{
			// 190508 first if arrays - inadequate type-checking but it should work for now...
			if (C.isArrayLike(src))
			{
				var len = src.length;

				for (var i = 0; i < len; ++i) {
					dest.push(src[i]);
				}
			}
			// else assume objects
			else if (src) {
				// trying to keep loop as fast as possible by avoiding comparison with exclude when possible:
				if (! exclude) {
					for (var prop in src) {
						// console.log('add: ', prop, src, dest); // 190510 debug
						dest[prop] = src[prop];
					}
				} else {
					for (var prop in src) {
						// 181230 note: probably would be useful to allow (exclude) to be an array
						if (prop !== exclude) {
							dest[prop] = src[prop];
						}
					}
				}
			}
			
			return C;
		},
	
	
		combine: function(os) {
			var newObj = {};
			C.each(os, C.add, newObj);
			return newObj;
		},
	
	
		extend: function(o, exts)
		{
			// exts is array/etc of method objects:
			if (C.isArrayLike(exts)) {
				C.each(exts, C.add, o);
			}
		
			// exts is object of method objects:
			else if (C.isObject(exts)) {
				for (var setName in exts) {
					var ext = exts[setName];
					C.add(ext, o);
				}
			}
		
			return o;
		},
	
		
		// deep copy - this only accounts for array elements, object properties that work with for...in, and direct contents (e.g. function bodies, primitive values) - and it doesn't deal with prototypes at all
		copy: function(orig)
		{
			// console.log('copy: orig = ', orig);

			var isObject = C.isObject(orig),

				clone =
					C.isArray(orig) ? C.map(orig, C.copy) :
					isObject ? {} :
					orig;
		
			// even if e.g. a function's properties are reference-copied above, the following should overwrite the ref-copies with deep copies - should test this though
			// not using mapFields because also want this prop-copying to happen to arrays (already constructed above), functions, etc.

			// 190508 ok the following being unconditional is causing infinite looping because strings and even single chars have numerical-index properties (just 0 for chars) so need to restrict this

			if (isObject) {
				for (var prop in orig) {
					// console.log('prop = ', prop);
					clone[prop] = C.copy(orig[prop]);
				}
			}
		
			return clone;
		},
	
	}; // end C





	// ScriptKnitter.js exposes all modules to global scope if window.TEST
	if (window.TEST) {
		C.U = U;
	};
	
	
	return C;
	
})(Modules);



Modules['FEB']['domFor'] = (function (M)
{
	var domFor = function(siteConfig)
	{
		var	C = M.FEB.Containers,
			Cfg = siteConfig || {
				hidden: '_hidden'
			};
				
		var Single = {
		
			id: function(domId) {
				return document.getElementById(domId);
			},
			oneElAddC: function($e, cls) {
				$e.classList.add(cls);
			},
			oneElRmvC: function($e, cls) {
				$e.classList.remove(cls);
			},

			// 190702 .replace doesn't work in IE so changing to remove -> add:

			oneElRplcC: function($e, oldC, newC) {
				// $e.classList.replace(oldC, newC);
				$e.classList.remove(oldC);
				$e.classList.add(newC);
				return D;
			},
			addC: function(cls, $es) {
				C.each($es, Single.oneElAddC, cls);
			},
			rmvC: function(cls, $es) {
				C.each($es, Single.oneElRmvC, cls);
			},
		
			oneElSetAttr: function($elmt, attrObj) {
				for (var name in attrObj)
				{
					var attr = attrObj[name];
				
					// previously 'cond', but this works more generally, flattening props of join-object to outer attr-set; note that this setup only allows a single cond/if/else group per attr-set; to have multiple, would need to allow a system like: cond: [{c: ..., y: ..., n: ...}, {c: ..., y: ..., n: ...}]
					if (name === 'join') {
						Single.oneElSetAttr($elmt, attr);
					}
					else {
						$elmt.setAttribute(name, attr);
					}
				}
				return D;
			},

			oneElRmvAttrs: function($elmt, attrs) {
				C.each(attrs, Single.oneElRmvOneAttr, $elmt);
				return D;
			},

			oneElRmvOneAttr: function(attr, $elmt) {
				$elmt.removeAttribute(attr);
				return D;
			},
		
			oneElSetData: function($e, dataObj) {
				for (var name in dataObj) {
					$e.setAttribute('data-' + name, dataObj[name]);
				}
				return D;
			},
		
			addCSSSelAndProps: function(sel, props, cssStrRef) {
				cssStrRef[0] += sel + ' {\n';
				for (var prop in props) {
					cssStrRef[0] += '\t' + prop + ': ' + props[prop] + ';\n';
				}
				cssStrRef[0] += '}\n';
				return D;
			},
		
			addCSSObjProp: function(selName, cssObj, cssStrRef) {
				var	props = cssObj[selName];
				Single.addCSSSelAndProps(selName, props, cssStrRef);
				return D;
			},
		
			addCSSSelPropsPair: function(selPropsPair, cssStrRef) {
				var	sel = selPropsPair[0],
					props = selPropsPair[1];
				Single.addCSSSelAndProps(sel, props, cssStrRef);
				return D;
			},
		
			addChild: function(ch, $e)
			{
				if (C.isString(ch)) {
					var $text = document.createTextNode(ch);
					$e.appendChild($text);
				}
				else if (C.isDomNode(ch)) {
					$e.appendChild(ch);
				}
				// otherwise assume json-like object (=> DOM Element, recursively):
				else
				{
					var $child = document.createElement(ch.t); // tag
				
					if (ch.c) { // classes
						D.addC($child, ch.c);
					}
					if (ch.id) {
						$child.id = ch.id;
					}
					if (ch.a) { // attributes object
						D.setAttr($child, ch.a);
					}
					if (ch.data) { // data- attributes
						D.setData($child, ch.data);
					}
					if (ch.hide) {
						D.hide($child);
					}
					if (ch.ch) { // children
						if (ch.t === 'style') {
							D.add($child, D.toCSS(ch.ch));
						} else {
							D.add($child, ch.ch);
						}
					}
					$e.appendChild($child);
				}
			},

		}; // end Single




		var U = {

			makeDomQuery: function(queryName, getSingleElement)
			{
				return function(name, $parent)
				{
					if ($parent === null) return null;
				
					var $set = ($parent || document)[queryName](name);
				
					return getSingleElement ? $set[0] : $set;
				};
			},

		};


		var Create = {

			fromStructure: function(structure, outputHtml)
			{
				try {
					if (C.isString(structure)) {
						return outputHtml ? structure : document.createTextNode(structure);
					}
					else if (C.isArray(structure)) {
						// console.log(structure);
						return Create.fromArray(structure, outputHtml);
					}
					else {
						throw Error('the argument should be either an array or a string.');
					}
				}
				catch (error) {
					var suffix = outputHtml ? 'Html' : '';

					console.warn('D.create' + suffix + '() - warning: ' + error.message, error.fileName, error.lineNumber);

					// temp for debugging
					throw error;

					return null;
				}
			},



			fromArray: function(structure, outputHtml)
			{
				var e0 = structure[0],
					e0IsArray = C.isArray(e0),
					len = structure.length;

				if (e0IsArray || e0 === '') {
					return Create.fragmentFromStructure(structure, outputHtml, len, e0IsArray);
				}
				else if (C.isString(e0)) {
					return Create.elementFromStructure(structure, outputHtml, len, e0);
				}
				else {
					// to be caught by D.create() caller:
					throw Error('the first element of the array argument should be: an HTML tag name string; an empty string; or an array.');
				}
			},


			fragmentFromStructure: function(structure, outputHtml, len, e0IsArray)
			{
				var childrenStart = e0IsArray ? 0 : 1;

				if (len - childrenStart === 1)
				{
					var singleChild = structure[childrenStart];
					return Create.fromStructure(singleChild, outputHtml);
				}
				else if (outputHtml)
				{
					return Create.childrenFromSubstructure(structure, outputHtml, childrenStart, len);
				}
				else
				{
					var $frag = D.fragment();
				
					Create.childrenFromSubstructure(structure, outputHtml, childrenStart, len, $frag);

					return $frag;
				}
			},


			elementFromStructure: function(structure, outputHtml, len, e0)
			{
				var tagOnly = len === 1,

					// NOTE think there might be a problem with self-closing with certain tags, maybe <script>? check on this (of course allowing <script> is a security issue if untrusted site users are able to do this - more reason for checking against an allowed-tag-names set as mentioned below ->)
					// 190504 seems to be a problem with self-closing in widget templates maybe so going ahead and doing separate closing tags:
					$e = outputHtml ?
						'<' + e0 + (tagOnly ? '>' + Create.closingTag(e0) : '') :
						document.createElement(e0);

				// this works whether outputHtml or not:
				if (tagOnly) { return $e; }


				var	noElement = (! outputHtml) && (! $e);

				if (noElement) {
					// to be caught by D.create() caller:
					// note if outputHtml, then this won't be caught; might eventually want to create a set of legit tag names to check against
					throw Error('could not create a DOM element using this supplied tag name: ' + e0);
				}



				var e1 = structure[1],
					hasAttrs = C.isObject(e1),
					noChildren = hasAttrs && len === 2;

				if (outputHtml) {
					if (hasAttrs) {
						for (var attrName in e1) {
							$e += ' ' + attrName + '="' + e1[attrName] + '"';
						}
					}

					$e += '>' + (noChildren ? Create.closingTag(e0) : '');
				}
				else if (hasAttrs) {
					D.setAttr($e, e1);
				}

				if (noChildren) { return $e };



				var childrenStart = hasAttrs ? 2 : 1;

				if (outputHtml) {
					$e +=
						Create.childrenFromSubstructure(structure, outputHtml, childrenStart, len) +
						Create.closingTag(e0);
				} else {
					Create.childrenFromSubstructure(structure, outputHtml, childrenStart, len, $e);
				}

				return $e;
			},


			closingTag: function(e) {
				return '</' + e + '>';
			},


			childrenFromSubstructure: function(structure, outputHtml, childrenStart, len, $e)
			{
				// could remove the repetition in the for-loops if implemented a start-&-end-index-including .each function in Containers
				// also could do it by putting the condition inside of the loop, but want to avoid that
				if (outputHtml)
				{
					var html = '';

					for (var i = childrenStart; i < len; ++i) {
						html += Create.childFromSubstructure(structure, outputHtml, i);
					}

					return html;
				}
				else
				{
					for (var i = childrenStart; i < len; ++i) {
						Create.childFromSubstructure(structure, outputHtml, i, $e);
					}

					return Create;
				}
			},
			

			childFromSubstructure: function(structure, outputHtml, i, $e)
			{
				var substructure = structure[i],
					$child = Create.fromStructure(substructure, outputHtml);
				
				if (outputHtml) {
					return $child || '';
				}
				else {
					if ($child) {
						$e.appendChild($child);
					}

					return Create;
				}
			},

		}; // end Create
	
	


		var D = {
		
			// element accessors:
		
			id: function(domIds) {
				return C.map(domIds, Single.id);
			},
            
			t: U.makeDomQuery('getElementsByTagName'),
			t1: U.makeDomQuery('getElementsByTagName', true),
			c: U.makeDomQuery('getElementsByClassName'),
			c1: U.makeDomQuery('getElementsByClassName', true),
			s: U.makeDomQuery('querySelectorAll'),
			s1: U.makeDomQuery('querySelector'),
		
			// children/innerHTML:

			html: function($e) {
				return $e.innerHTML;
			},
			addHtml: function($e, h) {
				$e.innerHTML += h;
				return D;
			},
			fillHtml: function($e, h) {
				$e.innerHTML = h;
				return D;
			},
		
			fill: function($e, chn) {
				$e.innerHTML = '';
				D.add($e, chn);
				return D;
			},
		
			add: function($e, chn) {
				C.each(chn, Single.addChild, $e);
				return D;
			},

			/** 190502 created for CKE ImageCollection updating functions;
			 * takes a json-like argument;
			 * each HTML element is represented by an array starting with tag name, then optional object of attributes, then children (not packed, just more array elements);
			 * if the first array element is an array or is the empty string, it's understood to represent a sequence of elements;
			 * non-first-element strings represent text nodes
			 * simplistic example:
				['form', {class: 'search'},
					['input', {type: 'text', class: 'widget-input'}],
					['input', {type: 'submit', class: 'widget-submit'}],
					['p', // no attrs
						'random paragraph text ',
						['b', 'bold part'],
						' more text ',
						['a', {href: '/'}, 'here\'s a link'],
						' yet more text'
					]
				]
			 * returns a single element if the structure has a single root, or a DocumentFragment if it has multiple top-level element;
			 * the result of both can be used with D.add after construction 
			 */
			create: function(structure) {
				return Create.fromStructure(structure, false);
			},

			createHtml: function(structure) {
				/* var result = Create.fromStructure(structure, true);
				console.log('createHtml: result = ', result);
				return result; */
				return Create.fromStructure(structure, true);
			},

		
			fragment: function() {
				return document.createDocumentFragment();
			},
			addViaFragment: function($e, chn) {
				var $frag = D.fragment();
				D
				.add($frag, chn)
				.add($e, $frag);
				return D;
			},
			fillViaFragment: function($e, chn) {
				var $frag = D.fragment();
				D
				.add($frag, chn)
				.fill($e, $frag);
				return D;
			},
		
			// classes:
		
			hasC: function($e, cls) {
				return $e.classList.contains(cls);
			},
			addC: function($es, clss) {
				C.each(clss, Single.addC, $es);
				return D;
			},
			rmvC: function($es, clss) {
				C.each(clss, Single.rmvC, $es);
				return D;
			},
			rplcC: function($es, oldC, newC) {
				C.each($es, Single.oneElRplcC, oldC, newC);
				return D;
			},
		
			// attributes:
		
			attr: function($e, attrName) {
				return $e.getAttribute(attrName);
			},
			setAttr: function($es, attrObj) {
				C.each($es, Single.oneElSetAttr, attrObj);
				return D;
			},
			rmvAttr: function($es, attrs) {
				C.each($es, Single.oneElRmvAttrs, attrs);
				return D;
			},
		
			data: function($e, dataAttrName) {
				return $e.getAttribute('data-' + dataAttrName); // this impl is for older IE compatibility, instead of using $e.data
			},
			hasData: function($e, dataAttrName) {
				return $e.hasAttribute('data-' + dataAttrName);
			},
			setData: function($es, dataObj) {
				C.each($es, Single.oneElSetData, dataObj);
				return D;
			},
		
			// show/hide:

			// 190505: need to test .show to see if rmvAttr fns are working
			// -> actually going to go back to ._hidden, seems safer
			show: function($es) {
				// D.rmvAttr($es, 'hidden');
				D.rmvC($es, Cfg.hidden);
				return D;
			},
			hide: function($es) {
				// D.setAttr($es, {hidden: ''});
				D.addC($es, Cfg.hidden);
				return D;
			},
			isHidden: function($e) {
				return $e.classList.contains(Cfg.hidden);
			},
		
			// ancestors/descendants:
		
			indexInList: function($list, $e) {
				return Array.prototype.indexOf.call($list, $e);
			},
		
			hasChild: function($parent, $child) {
				return D.childIndex($parent.children, $child) !== -1;
			},
		
			isAncestorOf: function($maybeAnc, $desc) {
				var $anc = $desc;
				while ($anc) {
					if ($maybeAnc === $anc) {
						return true;
					}
					$anc = $anc.parentNode;
				}
				return false;
			},
		
			childContaining: function($anc, $maybeDesc)
			{
				var $child = $maybeDesc,
					$parent;
			
				do {
					$parent = $child.parentNode;
					if ($anc === $parent) {
						return $child;
					}
					$child = $parent;
				} while ($parent);
			
				return null;
			},
		
			// misc:
		
			tag: function($e) {
				return $e.tagName.toLowerCase();
			},
			value: function($e) {
				return $e.value;
			},
		
			listen: function($elmt, evt, fn) {
				$elmt.addEventListener(evt, fn, false);
				return D;
			},
		
			// can take an object of objects whose outer properties are selectors and inner properties are CSS properties;
			// OR, to allow expressions in naming selectors: can take an array of pair-arrays, where the first of each pair is the selector, and the second of the pair is again a CSS-prop-object
			toCSS: function(cssObjOrArr)
			{
				var cssStrRef = [''];
			
				if (C.isArray(cssObjOrArr)) {
					C.each(cssObjOrArr, Single.addCSSSelPropsPair, cssStrRef);
				} else { // normal object
					C.eachField(cssObjOrArr, Single.addCSSObjProp, cssObjOrArr, cssStrRef);
				}

				return cssStrRef[0];
			},
		
		}; // end D
		
		
		if (window.TEST) {
			D.Single = Single;
		}
			
		return D;
		
	};
	
	// this should only be needed if there are no other domFor() instantiations
	if (window.TEST) {
		M.FEB = M.FEB || {};
		M.FEB.SampleDom = domFor();
	}
	
	
	return domFor;
	
})(Modules);



Modules['FEB']['Class'] = (function (M)
{
	var C = M.FEB.Containers;
	

	var ClassBuilder = {
	
		makeClass: function(interfacesOrMakeParts, makePartsIfInterfaces)
		{
			var args = ClassBuilder.interfacesAndParts(
					interfacesOrMakeParts, makePartsIfInterfaces),
				_class = {},
				partSrc = args.makeParts(_class);
			
			// log error if not conforming to interfaces:
			ClassValidator.checkInterfaceConformance(args.interfaces, partSrc);
	
			var	construct = ClassBuilder.hasInstanceParts(partSrc) ?
				ClassBuilder.makeConstructor(partSrc, _class) : {};
		
			ClassBuilder.addStaticMethods(partSrc, construct, _class);
	
			if (window.TEST) {
				// expose both regular constructor/API and private static methods:
				return {
					pub: construct,
					priv: _class
				};
			}

			return construct;
		},
	
	
		interfacesAndParts: function(interfacesOrMakeParts, makePartsIfInterfaces)
		{
			return makePartsIfInterfaces ? {
				interfaces: interfacesOrMakeParts,
				makeParts: makePartsIfInterfaces
			} : {
				interfaces: null,
				makeParts: interfacesOrMakeParts
			};
		},
	

		hasInstanceParts: function(partSrc)
		{
			var parts = ClassBuilder.instanceParts;
		
			for (var i = 0; i < parts.length; ++i)
			{
				var partID = parts[i],
					part = ClassBuilder.part(partSrc, partID);
			
				if (part) {
					return true;
				}
			}
			return false;
		},

	
		instanceParts: ['f', 'm', '_m'],

	
		part: function(partSrc, partName)
		{
			var nameOptions = ClassBuilder.partNames[partName],
				numNames = nameOptions.length;
		
			for (var i = 0; i < numNames; ++i)
			{
				var name = nameOptions[i],
					part = partSrc[name];
				
				if (part) {
					return part;
				}
			}
		
			return {}; // null
		},
	
	
		partNames: {
			f: ['f', 'fields', 'c', 'ctor'],
			m: ['m', 'pub', 'public', 'publicInstance', 'api', 'API', 'instanceAPI'],
			_m: ['_m', 'priv', 'private', 'privateInstance'],
			s: ['s', 'pubS', 'publicStatic', 'staticAPI'],
			_s: ['_s', 'privS', 'privateStatic']
		},
	

		makeConstructor: function(partSrc, _class)
		{
			return function()
			{
				var destObj = ClassBuilder.initObjectsAndRefs(_class);
		
				// putting field construction after the method additions in hopes that the object's methods can then be used in the constructor:
				ClassBuilder
				.addMethods(partSrc, destObj)
				.addFields(partSrc, destObj, arguments);
			
				if (window.TEST) {
					// expose both public & private objects:
					return destObj;
				}

				return destObj.pub;
			};
		},
	

		initObjectsAndRefs: function(_class) {
	
			// give private & public objects references to each other via function-calling so they can use each other's data and methods; public can only refer to private when key is passed to it, hence only in scope of key
			var	privObj;

			var pubObj = function(key) {
				return key === _class ? privObj : null;
			};

			privObj = function() {
				return pubObj;
			};
	
			return {
				pub: pubObj,
				priv: privObj
			};
		},


		addMethods: function(partSrc, destObj)
		{
			var	publicMethods = ClassBuilder.part(partSrc, 'm'),
				privateMethods = ClassBuilder.part(partSrc, '_m');

			C.add(privateMethods, destObj.priv)
			 .add(publicMethods, destObj.pub);
		
			return ClassBuilder;
		},


		addFields: function(partSrc, destObj, args)
		{
			var	ctor = ClassBuilder.part(partSrc, 'f');
	
			// applying to destObj.priv here in hopes of being able to do self-reference via (this) in .c's, e.g. for mutually dependent UIs & models;
			// 181230: changed to having .c's assign properties directly to the object, rather than a possible mix of assigning some properties & returning others; also this makes it easy to assign both private and public properties using (this) and (this()) respectively 
			if (ctor) {
				ctor.apply(destObj.priv, args);
			}
		
			return ClassBuilder;
		},

	
		addStaticMethods: function(partSrc, construct, _class)
		{
			var	publicStatic = ClassBuilder.part(partSrc, 's'),
				privateStatic = ClassBuilder.part(partSrc, '_s');

			// add public static methods to ctor (class object itself)
			// and turn the key into the holder of private static methods:
			C.add(publicStatic, construct)
			 .add(privateStatic, _class);
		
			return ClassBuilder;
		},
	
	}; // end ClassBuilder





	var ClassValidator = {
	
		checkInterfaceConformance: function(interfaces, partSrc)
		{
			if (interfaces)
			{
				var missingMethods = ClassValidator.missingMethods(interfaces, partSrc);
				
				ClassValidator.errorIfMissingMethods(missingMethods);
			}
		},
	
	
		missingMethods: function(interfaces, partSrc)
		{
			var interfaceList = C.makeArray(interfaces),
				missingMethods = {m: null, s: null};
		
			C.each(interfaceList, ClassValidator.addMissingMethodsFromInterface, {
				partSrc: partSrc,
				missingMethods: missingMethods
			});

			return missingMethods;
		},
	

		addMissingMethodsFromInterface: function(intfc, env)
		{
			C.eachField(env.missingMethods, ClassValidator.addMissingMethodsFromPart, {
				intfc: intfc,
				partSrc: env.partSrc,
				missingMethods: env.missingMethods
			});
		},


		addMissingMethodsFromPart: function(partName, env)
		{
			var intfcPart = ClassBuilder.part(env.intfc, partName),
				partHasMethods = intfcPart && ! C.isEmpty(intfcPart);

			if (partHasMethods)
			{
				var	classPart = ClassBuilder.part(env.partSrc, partName),
					intfcPartList = C.makeArray(intfcPart);
				
				C.each(intfcPartList, ClassValidator.addMissingMethod, {
					partName: partName,
					classPart: classPart,
					missingMethods: env.missingMethods
				});
			}
		},


		addMissingMethod: function(methodName, env)
		{
			var method = env.classPart[methodName];

			if (! method) {
				env.missingMethods[env.partName] = env.missingMethods[env.partName] || {};
				env.missingMethods[env.partName][methodName] = true;
			}
		},

	
		errorIfMissingMethods: function(missingMethods)
		{
			var methodsAreMissing = false;
		
			for (var partName in missingMethods) {
				if (missingMethods[partName] !== null) {
					methodsAreMissing = true;
				}
			}
		
			if (methodsAreMissing) {
				ClassValidator.logMissingMethodsError(missingMethods);
			}
		},
	

		logMissingMethodsError: function(missingMethods)
		{
			var errorMsgs = ClassValidator.errorMsgsForMissingMethods,
				errorMsg = errorMsgs.top;

			for (var partName in missingMethods)
			{
				var part = missingMethods[partName];
	
				if (part !== null)
				{
					errorMsg += errorMsgs.subtitles[partName];
		
					for (var methodName in part) {
						console.log('logMissingMethodsError(): methodName:', methodName);
						errorMsg += '\n\t\t' + methodName;
					}
				}
			}
	
			// to log the stack trace to show the class defn at fault, and then continue execution:
			C.logFullError(errorMsg);
		},


		errorMsgsForMissingMethods: {
			top: 'Class is missing the following interface methods:',
			subtitles: {
				m: '\n\tInstance methods:',
				s: '\n\tStatic methods:'
			}
		},

	}; // end ClassValidator


	
	if (window.TEST) {
		ClassBuilder.makeClass.ClassBuilder = ClassBuilder;
		ClassBuilder.makeClass.ClassValidator = ClassValidator;
	}

	return ClassBuilder.makeClass;

})(Modules);



Modules['WHS']['DOM'] = (function (M) {
	return M.FEB.domFor(M.WHS.Config);
})(Modules);



Modules['FEB']['initImageCollections'] = (function (M)
{
	// Element.matches support for IE9(& lower?), from MDN
	if (! Element.prototype.matches) {
	    Element.prototype.matches = Element.prototype.msMatchesSelector;
	}

	return function(DomForSite, SiteConfig)
	{
		var	C = M.FEB.Containers,
			Class = M.FEB.Class,
			D = DomForSite,
			Cfg = SiteConfig,
			ICConfig = Cfg.ImageCollection;
			
			
		// starting at 1 so that all values are truthy for existence checks:
		var ViewMode = {
			gallery: 1,
			slideshow: 2
		};


		var HandlerComponent = {
			m: ['$root', 'handleClick']
		};



		var ImageCollectionUI = Class(function(_class)
		{
			return {
				
				f: function($imgCollec)
				{
					// construction goes DOM-read -> ImageCollectionUI -> ImageCollection since it's determined by what's already in the DOM
					
					// need public object to pass to subcomponent ctors:
					var icUI = this();
					this.gallery = GalleryUI(icUI, $imgCollec);
					
					var imgCount = this.gallery.imgCount();
					this.model = ImageCollection(icUI, imgCount);
					
					this.$root = $imgCollec;
					this.toggle = ToggleUI(icUI, $imgCollec);
					this.navArrows = NavArrowsUI(icUI, $imgCollec);
					this.slide = SlideUI(icUI, $imgCollec);
					this.thumbnails = ThumbnailsUI(icUI, $imgCollec);
				},
				
				
				
				m: {
					
					updateData: function(data)
					{
						this(_class).model.update(data);
						// ^ model then calls updateView on this ICUI
						return this;
					},
					
					
					updateView: function(data)
					{
						var mode = data.viewMode,
							oldIndex = data.oldIndex,
							newIndex = data.newIndex;
						
						this(_class)
						.updateViewMode(mode)
						.updateSlideIndex(oldIndex, newIndex);
						
						return this;
					},


					currentViewMode: function() {
						return this(_class).model.currentViewMode();
					},
					
					
					currentIndex: function() {
						return this(_class).model.currentIndex();
					},
					
					
					lastIndex: function() {
						return this(_class).model.lastIndex();
					},
					
				}, // end m
				
				
				
				_m: {
					
					initSlideshow: function()
					{
						this
						.removeImgLinksFromTabindex()
						.listen();
			
						if (D.hasC(this.$root, 'slideshow-view'))
						{
							this().updateData({
								slideIndex: 0,
								viewMode: ViewMode.slideshow
							});
						}
						
						return this;
					},


					removeImgLinksFromTabindex: function()
					{
						var	imgLinksSel = '.' + ICConfig.figImgFld + ' a',
							$imgLinks = D.s(imgLinksSel, this.$root);
						
						/*
						console.log(
							'this.$root: ', this.$root,
							'\nimgLinksSel: ', imgLinksSel,
							'\n$imgLinks: ', $imgLinks
						);
						*/
						
						D.setAttr($imgLinks, {tabindex: -1});

						return this;
					},
					

					listen: function() {
						var handleClick = _class.makeClickHandler(this);
						D.listen(this.$root, 'click', handleClick);
						return this;
					},
					
					
					delegateClick: function($origin, evt)
					{
						var partNames = _class.handlerComponents;
					
						for (var i = 0; i < partNames.length; ++i)
						{
							var partName = partNames[i],
								part = this[partName];
						
							if (D.isAncestorOf(part.$root(), $origin)) {
								part.handleClick($origin, evt);
								break;
							}
						}
					},
					
					
					updateViewMode: function(mode)
					{
						switch (mode) {
						case ViewMode.slideshow:
							this.showSlideshow();
							break;
						case ViewMode.gallery:
							this.showGallery();
							break;
						}
						return this;
					},
					
					
					updateSlideIndex: function(oldIndex, newIndex)
					{
						if (oldIndex !== newIndex) {
							this.thumbnails.update(oldIndex, newIndex);
						
							var figureData = this.gallery.figureData(newIndex);
							this.slide.update(figureData);
						}
						return this;
					},
					
					
					showSlideshow: function() {
						D.rplcC(this.$root, 'gallery-view', 'slideshow-view');
						return this;
					},
	

					showGallery: function() {
						D.rplcC(this.$root, 'slideshow-view', 'gallery-view');
						return this;
					},
					
				}, // end _m
				
				
				
				s: {

					initializeAll: function() {
						var	$allICs = D.s('.' + ICConfig.imageCollection);
						C.each($allICs, _class.initIfSlideshowEnabled);
					},
					
					
					// for subcomponents (thumbnails, gallery) to use:
					newIndexFrom: function($origin, $section, $figures)
					{
						var $childFig = D.childContaining($section, $origin);
						return D.indexInList($figures, $childFig);
					},
					
				},
				
				
				
				_s: {
			
					initIfSlideshowEnabled: function($imgCollec)
					{
						if (! D.hasC($imgCollec, 'gallery-only')) {
							var icUI = ImageCollectionUI($imgCollec);
							
							// taking advantage of ability to access private instance objects even from static methods:
							icUI(_class).initSlideshow();
						}
					},
					
					
					handlerComponents: ['toggle', 'thumbnails', 'gallery'],
					
					
					makeClickHandler: function(_icUI)
					{
						return function(evt)
						{
							// ••••• 181230 note: if move nav arrows into their own single parent element (e.g. just below the slide), then 'navArrows' should be able to be added to _class.handlerComponents instead of its separate treatment here:
							
							var $origin = evt.target,
								originIsInNavArrows = $origin.matches(NavArrowsUI.fullSel());
							
							if (originIsInNavArrows) {
								_icUI.navArrows.handleClick($origin);
							}
							else {
								_icUI.delegateClick($origin, evt);
							}
						};
					},
					
				}, // end _s
				
			};
			
		}); // end ImageCollectionUI






		var ImageCollection = Class(function(_class)
		{
			return {
				
				f: function(imgCollecUI, imgCount)
				{
					this.UI = imgCollecUI;
					this.lastIndex = imgCount - 1;
					this.currentIndex = null;
					this.oldIndex = null;
					this.viewMode = ViewMode.gallery;
				},
				
				
				
				m: {

					currentViewMode: function() {
						return this(_class).viewMode;
					},
					
					currentIndex: function() {
						return this(_class).currentIndex;
					},
					
					lastIndex: function() {
						return this(_class).lastIndex;
					},
					
					
					update: function(data)
					{
						var _ic = this(_class);
						
						if (data.slideIndex !== undefined) {
							_ic.oldIndex = _ic.currentIndex;
							_ic.currentIndex = data.slideIndex;
						}
						else if (_ic.currentIndex === null) {
							_ic.currentIndex = 0;
						}
						
						if (data.viewMode !== undefined) {
							_ic.viewMode = data.viewMode;
						}
						
						_ic.UI.updateView({
							oldIndex: _ic.oldIndex,
							newIndex: _ic.currentIndex,
							viewMode: _ic.viewMode,
						});
						
						return this;
					},
					
				},
			};
		}); // end ImageCollection






		var ToggleUI = Class(HandlerComponent, function(_class)
		{
			return {
				
				f: function(imgCollecUI, $imgCollec)
				{
					this.parent = imgCollecUI;
					this.$root = D.s1('.' + ICConfig.toggle, $imgCollec);
				},
				
				
				
				m: {
					handleClick: function($origin)
					{
						var	_icUI = this(_class).parent,

							newViewMode =
								_icUI.currentViewMode() === ViewMode.gallery ?
									ViewMode.slideshow :
									ViewMode.gallery;
						
						_icUI.updateData({
							viewMode: newViewMode
						});
						
						return this;
					},
					
					
					$root: function() {
						return this(_class).$root;
					},
				},
				
			};
		}); // end ToggleUI






		var SlideUI = Class(function(_class)
		{
			return {

				f: function(imgCollecUI, $imgCollec)
				{
					var $ic = $imgCollec;
					this.$title = this.elmt($ic, ICConfig.slideTitle); // 'title'
					this.$link = this.elmt($ic, ICConfig.slideLink); // 'link'
					this.$image = this.elmt($ic, ICConfig.slideImg); // 'image'
					this.$caption = this.elmt($ic, ICConfig.slideCaption); // 'caption'
				},
				
				
				
				m: {
					update: function(figureData)
					{
						var	_slide = this(_class);

						D
						.fillHtml(_slide.$title, figureData.title)
						.setAttr(_slide.$link, {href: figureData.link})
						.setAttr(_slide.$image, {
							src: figureData.imgSrc,
							alt: figureData.imgAlt
						})
						.fillHtml(_slide.$caption, figureData.caption);
			
						return this;
					},
				},
				
				
				
				_m: {
					elmt: function($imgCollec, className /*classSuffix*/)
					{
						// var sel = '.' + ICConfig.slidePrefix +
						// 	(classSuffix? '-' + classSuffix : '');
						var sel = '.' + className;
				
						return D.s1(sel, $imgCollec);
					},
				}
				
			};
		}); // end SlideUI






		var NavArrowsUI = Class(function(_class)
		{
			return {
				
				f: function(imgCollecUI, $imgCollec)
				{
					this.parent = imgCollecUI;
					this.$parent = $imgCollec;
					
					// need this.$parent to be defined before calling this.elmt:
					this.buttons = {
						$prev: this.elmt('prev'),
						$next: this.elmt('next'),
						$first: this.elmt('first'),
						$last: this.elmt('last'),
					};
				},
				
				
				
				m: {
					handleClick: function($origin)
					{
						var type = _class.chooseArrowTypeFrom($origin);
						this(_class).loadFromArrow(type);
						return this;
					},
				},
				
				
				
				_m: {
					
					loadFromArrow: function(type)
					{
						var newIndex = this.newIndex(type);
						
						if (newIndex !== null) {
							this.parent.updateData({slideIndex: newIndex});
						}
						
						return this;
					},
					
					
					newIndex: function(type)
					{
						var	current = this.parent.currentIndex(),
							last = this.parent.lastIndex();
							
						return (
							type === 'next' ? (current === last ? 0 : current + 1) :
							type === 'prev' ? (current === 0 ? last : current - 1) :
							type === 'first' ? 0 :
							type === 'last' ? last :
							null
						);
					},
					
					
					elmt: function(arrowType) {
						var sel = _class.singleSels(arrowType);
						return D.s1(sel, this.$parent);
					},
					
				},
				
				
				
				s: {
					fullSel: function() {
						return _class.fullSel;
					}
				},
				
				
				
				_s: {
					
					fullSel: '.' + ICConfig.slideNavButton + ', .' + ICConfig.slideNavButton + ' *',
					
					arrowTypes: ['first', 'prev', 'next', 'last'],
					
			
					singleSel: function(arrowType) {
						var classSel = '.' + ICConfig.slideNavButton + '.-' + arrowType;
						return classSel + ', ' + classSel + ' *';
					},
					
					
					// so that selector strings don't have to be recomputed on every arrow click:
					singleSels: C.lazyFn(function() {
						var arrowTypeSels = C.mapFieldList(_class.arrowTypes, _class.singleSel);

						// console.log('arrowTypeSels\n', arrowTypeSels);
						
						return function(arrowType) {
							return arrowTypeSels[arrowType];
						};
					}),
					
					
					chooseArrowTypeFrom: function($origin)
					{
						for (var i = 0; i < _class.arrowTypes.length; ++i)
						{
							var arrowType = _class.arrowTypes[i],
								arrowTypeSel = _class.singleSels(arrowType),
								arrowIsOrigin = $origin.matches(arrowTypeSel);
					
							if (arrowIsOrigin) {
								return arrowType;
							}
						}
					},
					
				},
				
			};
		}); // end NavArrowsUI






		var ThumbnailsUI = Class(HandlerComponent, function(_class)
		{
			return {
				
				f: function(imgCollecUI, $imgCollec)
				{
					this.parent = imgCollecUI;
					this.$section = D.s1('.' + ICConfig.thumbnails, $imgCollec);
					this.$thumbnails = D.s('.' + ICConfig.thumbnailImg, this.$section);
				},
				
				
				
				m: {
					
					update: function(oldIndex, newIndex)
					{
						this(_class)
						.centerThumbnail(newIndex)
						.changeSelectedThumbnail(oldIndex, newIndex);
						
						return this;
					},
					
					
					handleClick: function($origin, evt)
					{
						if ($origin.matches(_class.singleSel))
						{
							var	_tn = this(_class),
								newIndex = _tn.newIndexFrom($origin);
								
							_tn.parent.updateData({
								slideIndex: newIndex,
								viewMode: ViewMode.slideshow // <- •••• 181230 should be able to remove viewMode call once thumbs & gallery are separated, since only gallery will need it for switching from gallery to slideshow view
							});
								
							evt.preventDefault();
						}
						
						return this;
					},
					
					
					$root: function() {
						return this(_class).$section;
					},
					
				},
				
				
				
				_m: {
	
					changeSelectedThumbnail: function(oldIndex, newIndex)
					{
						if (oldIndex !== null) {
							var $oldThumbnail = this.$thumbnails[oldIndex];
							D.rmvC($oldThumbnail, 'selected');
						}
						
						var $newThumbnail = this.$thumbnails[newIndex];
						D.addC($newThumbnail, 'selected');
			
						return this;
					},
	

					centerThumbnail: function(newIndex)
					{
						var	$figure = this.$thumbnails[newIndex],
							barCenter = this.$section.offsetWidth / 2,
							figCenter = $figure.offsetLeft + $figure.scrollWidth / 2;
	
						this.$section.scrollLeft = figCenter - barCenter;
			
						return this;
					},
					
					
					newIndexFrom: function($origin) {
						return ImageCollectionUI.newIndexFrom(
							$origin, this.$section, this.$thumbnails);
					},
					
				},
				
				
				
				_s: {
					singleSel: '.' + ICConfig.thumbnails +
						' .' + ICConfig.thumbnailImg +
						', .' + ICConfig.thumbnails +
						' .' + ICConfig.thumbnailImg + ' *',
				},
				
			};
		});






		// ••••• 181230 NOTE: .gallery not reached currently because clicks are always delegated to .thumbnails, which currently have same check as .gallery
		var GalleryUI = Class(HandlerComponent, function(_class)
		{
			return {
				
				f: function(imgCollecUI, $imgCollec) {
					this.parent = imgCollecUI;
					this.$section = D.s1('.' + ICConfig.gallery, $imgCollec);
					this.$figures = D.s('.' + ICConfig.galleryFigure, this.$section);
				},
				
				
				
				m: {
					
					// currently basically a repetition of thumbnails.handleClick (and not reached currently anyway), but keeping them separate in case of later behavior divergence, and for cleaner organization
					handleClick: function($origin, evt)
					{
						var _g = this(_class);
						
						if ($origin.matches(_class.singleSel))
						{
							var newIndex = _g.newIndexFrom($origin);
							
							_g.parent.updateData({
								slideIndex: newIndex,
								viewMode: ViewMode.slideshow
							});
							
							evt.preventDefault();
						}
						
						return this;
					},
					
					
					$root: function() {
						return this(_class).$section;
					},
					
					
					imgCount: function() {
						return this(_class).$figures.length;
					},
					
					
					figureData: function(figIndex)
					{
						var	$figure = this(_class).$figures[figIndex],
							$imgFld = D.s1('.' + ICConfig.figImgFld, $figure),
							$title = D.s1('.' + ICConfig.figTitle, $figure),
							$link = D.s1('a', $imgFld),
							$image = D.s1('img', $imgFld),
							$caption = D.s1('.' + ICConfig.figCaption, $figure),
							imgSrc = D.attr($image, 'src');

						return {
							title: D.html($title),
							link: $link ? D.attr($link, 'href') : imgSrc,
							imgSrc: imgSrc,
							imgAlt: D.attr($image, 'alt'),
							caption: D.html($caption)
						};
					},
					
				},
				
				
				
				_m: {
					// again a near-repetition of thumbnails(priv).newIndexFrom, for now at least
					newIndexFrom: function($origin) {
						return ImageCollectionUI.newIndexFrom(
							$origin, this.$section, this.$figures);
					},
				},
				
				
				
				_s: {
					singleSel: '.' + ICConfig.gallery +
						' .' + ICConfig.galleryFigure +
						', .' + ICConfig.gallery +
						' .' + ICConfig.galleryFigure + ' *',
				},
				
			};
			
		}); // end GalleryUI






		if (window.TEST) {
			
			M.FEB = M.FEB || {};
			
			// 181230: if window.TEST, makeClass exposes all instance & static methods/properties publicly via .pub and .priv, e.g. var ic = (...).ImageCollection.pub(...); ic.pub.(...), ic.priv.(...)
			
			M.FEB.initImageCollectionsClasses = {
				ImageCollectionUI: ImageCollectionUI,
				ImageCollection: ImageCollection,
				ToggleUI: ToggleUI,
				SlideUI: SlideUI,
				NavArrowsUI: NavArrowsUI,
				ThumbnailsUI: ThumbnailsUI,
				GalleryUI: GalleryUI
			};
			
			return;
		}



		ImageCollectionUI.initializeAll();


		return M.FEB;
	
	}; // end initImageCollections fn defn
})(Modules);



Modules['WHS']['init'] = (function (M)
{
	
	var	C = M.FEB.Containers;

	/** run this on domReady (below defn) in order to ensure full DOM is loaded, not just JS dependencies */
	var WHS_init = function()
	{
		var	D = M.WHS.DOM,
			Search = M.WHS.Config.Search,
			Nav = M.WHS.Config.Nav;

		var Init = {

			/** hide all <p>&nbsp;</p> created by CKEditor: */
			hideBlankPs: (function()
			{
				var hideSingleBlankP = function($p) {
						if (D.html($p).trim() === '&nbsp;') {
							D.hide($p);
						}
					},

					$paragraphs = D.s('p');

				return function() {
					C.each($paragraphs, hideSingleBlankP);
					return Init;
				};

			})(),


			listenToggles: (function()
			{
				var nodeToggleSel = '.' + Nav.nodeToggle,
					closerSel = '.' + Nav.closer,
					$navToggle = D.id(Nav.navToggleId),
					$searchToggle = D.id(Search.toggleId),
					$searchForm = D.id(Search.formId),
					$searchBar = D.id(Search.inputId),
					$headerNav = D.id(Nav.headerRootId),
					$siteMap = D.id(Nav.siteMapId),

					types = {
						nav: 'nav',
						submenu: 'submenu',
						search: 'search',
					},

					states = {
						closed: 'closed',
						open: 'open',
					},

					toggleLabels = {
						nav: Nav.toggleLabel.main,
						submenu: Nav.toggleLabel.sub,
						search: Search.toggleLabel,
					},

					toggleAttrsToSet = {
						all: {
							closed: {},
							open: {'aria-pressed': 'true'},
						},
					},


					setTypeAttrSets = function(type)
					{
						var typeAttrSets = toggleAttrsToSet[type] = {},
							typeLabels = toggleLabels[type];

						C.eachField(states, setStateAttrSet, typeLabels, typeAttrSets);
					},

					setStateAttrSet = function(state, typeLabels, typeAttrSets)
					{
						var label = typeLabels[state];

						typeAttrSets[state] = {
							'aria-label': label,
							title: label,
						};
					};

				
				C.eachField(types, setTypeAttrSets);



				var	handleToggle = function($toggle, type)
					{
						var $dropdown;

						if (type === types.search) {
							$dropdown = $searchForm;
						}
						else {
							var $parent = $toggle.parentElement;
							$dropdown = $parent.querySelector('ul');
						}
						
						if ($dropdown.hasAttribute('open')) {
							closeDropdown($dropdown, $toggle, type);
						}
						else {
							openDropdown($dropdown, $toggle, type);
						}
					},


					handleCloser = function($closer)
					{
						var $dropdown = $closer.parentElement.parentElement,
							$toggle = $dropdown.parentElement.querySelector(nodeToggleSel);
						
						closeDropdown($dropdown, $toggle, types.submenu);
					},


					openDropdown = function($dropdown, $toggle, type)
					{
						if (type === types.submenu) {
							$toggle.innerHTML = '–';
						}

						D.setAttr($toggle, toggleAttrsToSet[type].open)
						 .setAttr($toggle, toggleAttrsToSet.all.open);

						$dropdown.setAttribute('open', '');

						if (type === types.search) {
							$searchBar.focus();
						}
					},

					
					closeDropdown = function($dropdown, $toggle, type)
					{
						if (type === types.submenu) {
							$toggle.innerHTML = '+';
						}

						D.setAttr($toggle, toggleAttrsToSet[type].closed);

						$toggle.removeAttribute('aria-pressed');
						$dropdown.removeAttribute('open');
					},


					handlers = {

						nav: function(evt) {
							handleToggle($navToggle, types.nav);
						},

						submenu: function(evt)
						{
							var $origin = evt.target;

							if ($origin.tagName === 'BUTTON') {

								if ($origin.matches(nodeToggleSel)) {
									handleToggle($origin, types.submenu);
								}
								else if ($origin.matches(closerSel)) {
									handleCloser($origin, types.submenu);
								}
							}
						},

						search: function(evt) {
							handleToggle($searchToggle, types.search);
						},
					};

				
				return function()
				{
					D.listen($navToggle, 'click', handlers.nav)
					 .listen($headerNav, 'click', handlers.submenu)
					 .listen($searchToggle, 'click', handlers.search)
					 .listen($siteMap, 'click', handlers.submenu);

					return Init;
				};

			})(), // end listenToggles

		};


		Init
		.hideBlankPs()
		.listenToggles();

		M.FEB.initImageCollections(D, M.WHS.Config);
		
		
		if (window.TEST) {
			return Init;
		}

	}; // end WHS_init
	

	C.runOnDomReady(WHS_init, false);
	
})(Modules);



if (window.TEST) {
	window.ScriptKnitter = {M: Modules};
};


})();
